<template>
  <div>
    <StudentData :student-datas="studentDatas" />
    <FamilyStudentData :student-datas="studentDatas" />
    <StudentEducationData :student-datas="studentDatas" />
    <StudentDocument :student-datas="studentDatas" />
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import StudentData from '../list/detail/StudentData'
import FamilyStudentData from '../list/detail/FamilyStudentData'
import StudentEducationData from '../list/detail/StudentEducationData'
import StudentDocument from '../list/detail/StudentDocument'


export default {
  components: {
    StudentData,
    FamilyStudentData,
    StudentEducationData,
    StudentDocument,
  },
  props: {},
  data: () => ({
    studentDatas: {},
  }),
  computed: {
    studentClass() {
      return this.studentDatas?.academic_classes?.data?.master_class?.data
        ?.class_desc
    },
    studentBirth() {
      return this.studentDatas?.birth_place
    },
    studentAddress() {
      return this.studentDatas?.address
    },
  },
  created() {
    this.detailStudent()
  },
  methods: {
    async detailStudent() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/students/${studentId}`)
        this.studentDatas = response.data.data
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style></style>
